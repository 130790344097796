.add-product{
    .image-placeholder{
        .avatar-preview{
            height: 9rem !important;
        }
        .delete-img{
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 2px 6px;
            z-index: 1;
            background: #fff;
            border: 0;
            border-radius: 50%;
            .la{
                color: var(--primary);
                font-size: 18px;
            }
        }
        img{
            border-radius: 30px;
        }
    }
    .add-more-items{
        color: #dedede;
        background: none;
        font-size: 32px;
        border: 2px solid #dedede;
        border-radius: 50%;
        padding: 8px 16px;    
    }
}
.css-1nmdiq5-menu{
    z-index: 999999!important;
}

.color{
    font-size: 20px;
    margin-top: 3px;
    color: #fff;
}
  
.wrapperClassName{
    height: 400px
}
.editorClassName{
    padding: 12px !important;
    border: 1px solid #dedede;
    border-radius: 8px;
}
.tabs-div {
    // padding-bottom: 5px;
    border-bottom: 1px solid #e8eaeb;
    // margin-bottom: 1rem;
    display: flex;
    gap: 12px;
    width: fit-content;

    span {
      padding: 5px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
    p{
        width: fit-content;
        cursor: pointer;
        padding: 2px 16px;
    }
}
.showDes{
    width: 400px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-height: 1.5;
    min-height: 4rem;
    border-top: 0 !important;
    padding: 2px 6px !important;
}
.product-row{
    border-bottom: 1px solid #dededed3;
    td{
        border-right: 1px solid #dededed3;
        text-align: center;
        line-height: 1.3;
    }
}