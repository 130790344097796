.rules{
    .form-check-label{
        margin: 0 1.5rem;
    }
    .rule-section{
        padding: 1rem;
        border: 1px solid #dedede;
        border-radius: 8px;
        height: 100%;
    }
}