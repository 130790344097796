.our-clients{
    .image-placeholder{
        .avatar-preview{
            height: 9rem !important;
        }
        
        img{
            border-radius: 30px;
        }
    }
    .add-client{
        text-align: center;
        i{
            font-size: 50px;
            background: #dedede;
            color: #fff;
            border-radius: 50%;
            padding: 10px;
        }
    }
}